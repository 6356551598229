export default {
  page: {
    title: {
      base: 'Autopay fizetési átjáró',
      start: 'Fizetési mód választása',
      visadata: 'Visa Mobile',
      continue: 'Folytatás – Fizetési mód kiválasztása',
      wait: 'Elvárás',
      confirmation: 'Fizetési visszaigazolás',
      thankYou: 'Köszönjük a fizetést',
      error: 'Oldalhiba',
      errorNotFound: 'Az oldal nem található',
      secureBack: 'Oldal betöltése',
      maintenance: 'Webhely karbantartás alatt',
      regulationsApproval: 'Szabályzat és adatvédelmi szabályzat',
      toTransferData: 'átutalás adatai',
      sessionTimeout: 'Oldal az ülés vége után',
      paywayError: 'A fizetési csatorna nem érhető el'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Betűméret növelése',
      decreaseFontSize: 'Betűméret csökkentése',
      changeContrast: 'Kontraszt módosítása',
      changeLanguage: 'A webhely nyelvének módosítása',
      skipToContent: 'Ugrás a tartalomra',
      skipToContentLabel: 'Ugrás a fő tartalomhoz',
      logoTitle: 'Webhely logója'
    },
    sessionTimer: {
      timeOut: 'A munkamenet lejárt',
      sessionEnd: 'Munkamenet vége:',
      label: 'Munkamenet idejének meghosszabbítása',
      button: 'Meghosszabbít'
    }
  },
  footer: {
    text1: 'További kérdései vannak az online fizetésekkel vagy egyéb szolgáltatásainkkal kapcsolatban? <a title="Megnyílik egy új ablakban" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" target="_blank" rel="noreferrer">Lépjen a Súgó oldalra</a>',
    text2: {
      part1: 'A személyes adatok kezelője a Autopay S.A.',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. A személyes adatok megadása önkéntes, de a benyújtott kérelem végrehajtásához szükséges. A személyes adatok kezelésének jogalapját, célját, időtartamát, valamint a Felhasználó jogait, valamint a személyes adatok kezelésének elveire vonatkozó egyéb fontos információkat részletesen meghatározzuk a következőben: <a href="{privacyPolicyUrl}" target="_blank" title="Ugrás az adatvédelmi irányelv dokumentumot tartalmazó oldalra (128 KB, PDF)" rel="noreferrer">A Autopay S.A. adatvédelmi irányelvei.</a>'
    },
    linkShowMoreTitle: 'További információk megtekintése',
    linkShowMoreText: 'Olvass tovább',
    linkShowLessTitle: 'További információk elrejtése',
    linkShowLessText: 'Olvass kevesebbet'
  },
  paywall: {
    header: 'Fizetési mód kiválasztása',
    info: {
      paywayNotAvailable: 'Nagyon sajnáljuk, de úgy tűnik, hogy az Ön által kiválasztott fizetési mód jelenleg nem lehetséges a készülékén.',
      applePayNotAvailable: 'Nagyon sajnáljuk, de úgy tűnik, hogy jelenleg nem tud fizetni az Apple Pay szolgáltatással a készülékén.',
      googlePayNotAvailable: 'Nagyon sajnáljuk, de úgy tűnik, hogy jelenleg nem tud fizetni a Google Pay szolgáltatással a készülékén.'
    },
    paywayGroup: {
      inactiveMessage: 'A fizetés jelenleg nem elérhető',
      notice: {
        novelty: 'ÚJDONSÁG',
        promotion: 'KÜLÖNLEGES AJÁNLAT',
        recommended: 'AJÁNLOTT',
        mastercard: 'A LEGBIZTONSÁGOS FIZETÉS AZ INTERNETEN',
        mastercardC2P: 'A LEGBIZTONSÁGOS FIZETÉS AZ INTERNETEN',
        blik: 'BLIKOMANIA - Regisztráljon és nyerjen gyémántokat!',
        visaMobile: 'Fizesd simán és biztonságosan'
      },
      bank: {
        many: 'bank',
        one: 'bank',
        two: 'bank'
      },
      wallet: {
        many: 'pénztárca',
        one: 'pénztárca',
        two: 'pénztárca'
      },
      otp: {
        many: 'módokon',
        one: 'út',
        two: 'módokon'
      },
      blikPayLater: {
        info: 'Tudj meg többet'
      }
    },
    paywayList: {
      label: 'Fizetéstípus-csoport',
      linkSelectOther: 'Válasszon másikat'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Van számlám egy másik bankban'
    },
    paywaySwitch: {
      methodShortSingle: 'Egyszer',
      methodLongSingle: 'Egyszeri kifizetések',
      methodShortAuto: 'Ciklikus',
      methodLongAuto: 'Ismétlődő kifizetések'
    },
    form: {
      payway: {
        label: 'Fizetési mód kiválasztása'
      },
      card: {
        label: 'Adja meg a kártya adatait',
        iframe: {
          title: 'Kitöltendő hitelkártyaadat űrlap'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Telefonszám megadására szolgaló űrlap'
        },
        status: {
          pending: {
            statusText: 'Megerősítésre vár',
            header: 'Erősítse meg a fizetést az alkalmazásban',
            descriptionText1: 'A fizetést megerősítheti a Visa Mobile alkalmazásban vagy az aktív Visa Mobile szolgáltatással rendelkező banki alkalmazásban',
            descriptionText2: 'Miután megerősítette a tranzakciót az alkalmazásban, várja meg a fizetés eredményét',
            descriptionText3: 'A tranzakció törlésre folyamatban van',
            cancelButton: 'Törölje a fizetést'
          },
          success: {
            statusText: 'Köszönjük a vásárlást!',
            descriptionText: 'Sikeres fizetés'
          },
          error: {
            statusText: 'Győződjön meg arról, hogy elegendő pénzösszeg áll rendelkezésére a Visa Mobile fizetéshez használt bankkártyán.',
            descriptionText: 'Fizetés elutasítva'
          }
        },
        apps: 'Fizetést támogató alkalmazások Visa Mobile',
        help: 'Segítségre van szüksége?',
        info: 'Tudjon meg többet',
        secure: 'Biztonságos fizetés SSL titkosítással',
        modal2: {
          header: 'A fizetés megerősítéséhez nyissa meg a Visa Mobile alkalmazást vagy azt a bankalkalmazást, amelyben aktív Visa Mobile szolgáltatása van',
          listText1: 'van telepített Visa Mobile alkalmazása vagy aktív Visa Mobile szolgáltatása a bankjának mobilalkalmazásában',
          listText2: 'az értesítések a Visa Mobilalkalmazásban vagy az aktív Visa Mobile szolgáltatással rendelkező banki alkalmazásban be vannak kapcsolva',
          listText3: 'z Ön által megadott szám helyes: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Győződjön meg arról is, hogy...'
        }
      },
      click2pay: {
        loaderText: 'Az Ön e-mail címét keressük <br>a <strong>Click to Pay alkalmazásban</strong>',
        saveCard: {
          text1: 'Mentsd el a kártyádat',
          text2: 'Adatait biztonságosan elmentjük <strong>Mastercard Click to Pay</strong>',
          link: 'További információ'
        },
        email: {
          labelForClick2Pay: 'E-mail cím fizetési értesítésekhez'
        },
        cardSelect: {
          selected: 'Kiválasztva',
          select: 'Kiválasztás',
          payOther: 'Adja meg a kártya adatait',
          back: 'Vissza'
        },
        cardSelected: {
          header: 'A Click to Pay szolgáltatásban mentett kártya használata',
          date: 'érvényes ig',
          buttonChange: 'Változás'
        },
        codeForm: {
          description: 'Megtaláltuk e-mail címét a Click to Pay szolgáltatásban.',
          codeSentEmail: 'Adja meg az egyszeri kódot, amelyet a(z) {email} címre küldtünk a fiókjába való bejelentkezéshez.',
          codeSentPhone: 'Adja meg az egyszeri SMS-kódot, amelyet a(z) {phone} számra küldtünk a fiókjába való bejelentkezéshez.',
          codeLabel: 'Írja be a kódot',
          codePlaceholder: 'kód megadása',
          codeLabelFlow: 'Kód',
          codeSendLabel: 'Új kód küldése',
          codeSendAriaLabel: 'via',
          codeSendAriaLabel2: 'vagy',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-mail',
          trustedLabel: 'Adja hozzá ezt az eszközt a megbízható listához<br><span>Legközelebb felismerjük ezt az eszközt, és bejelentkezés nélkül fog fizetni</span>',
          buttonSubmit: 'Következő',
          buttonChangeCard: 'Adja meg a kártya adatait'
        },
        info: {
          description: 'A kártyás fizetés titkosított és biztonságos. A tranzakció engedélyezése a bank honlapján található 3DSecure segítségével történik.'
        },
        loginForm: {
          emailLabel: 'Adja meg e-mail címét a Click to Pay szolgáltatásba való bejelentkezéshez',
          emailPlaceholder: 'e-mail cím',
          emailLabelFlow: 'Email',
          buttonSubmit: 'Bejelentkezés',
          buttonBack: 'Vissza'
        },
        newUserForm: {
          header: 'Kártya mentése kattintással fizetésre',
          description: 'Még néhány adatra van szükségünk a kártya mentéséhez.',
          emailLabel: 'Adja meg az e-mail címet',
          emailPlaceholder: 'e-mail cím',
          emailLabelFlow: 'Email',
          countryLabel: 'Ország',
          firstnameLabel: 'Név',
          firstnamePlaceholder: 'név',
          firstnameLabelFlow: 'Név',
          lastnameLabel: 'Vezetéknév',
          lastnamePlaceholder: 'lastname',
          lastnameLabelFlow: 'Lastname',
          phoneLabel: 'Telefonszám',
          phonePlaceholder: 'telefonszám',
          phoneLabelFlow: 'Phone',
          countryCodePlaceholder: 'code',
          countryCodeLabelFlow: 'Kód',
          trustedLabel: 'Adja hozzá ezt az eszközt a megbízható listához<br><span>Legközelebb felismerjük ezt az eszközt, és bejelentkezés nélkül fog fizetni</span>',
          termsLabel: 'A folytatással elfogadja a <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Használati feltételeket</a> és Ön tudomásul veszi, hogy adatait a <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Adatvédelmi szabályzat</a>Mastercard.',
          c2pInfo: 'A „Click to Pay” ezeket az információkat fogja felhasználni az Ön ellenőrzésére, és az ellenőrző kódokat erre a számra küldi. Üzenet/adatdíjak vonatkozhatnak.',
          buttonSubmit: 'Mentés és fizetés',
          buttonBack: 'Fizetés a kártya mentése nélkül'
        },
        profile: {
          header: 'Jelentkezzen be Click to Pay',
          buttonChangeEmail: 'E-mail cím módosítása'
        },
        modal: {
          title: 'Kattintással fizet - információ',
          header: 'Fizessen gyorsan és biztonságosan anélkül, hogy átírná a kártyaadatait az új Click to Pay szabvány szerint',
          text1: 'Kártyás fizetéskor keresse meg a <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> logót, és fizessen bankkártyájával gyorsan és kényelmesen.',
          text2: 'A Click To Pay egy új kártyás fizetési szabvány, amelyet a Visa és a Mastercard támogat.',
          text3: 'A Click to Pay számláján lévő kártyával fizethet lengyelországi és külföldi üzletekben, a kártyaadatok másolása nélkül.',
          text4: 'Az adatokat biztonságosan menti a Visa vagy a Mastercard. Minden információ teljesen titkosított.',
          text5: 'A későbbi fizetések során a Click to Pay felismeri az Ön e-mail címét, így azonnal fizethet elmentett kártyáival.',
          text6: 'További megerősíti a befizetéseket bankjával, ami garantálja a teljes biztonságot.',
          processError: 'Hiba történt a Click to Pay fizetés végrehajtásakor. Végezzen normál kártyás fizetést.'
        }
      },
      email: {
        label: 'Adja meg e-mail címét',
        flowLabel: 'E-mail',
        tip: 'Szükségünk van az e-mail címére, hogy üzeneteket küldhessünk Önnek a fizetések állapotáról.'
      },
      terms: {
        header: 'Hozzájárulások és nyilatkozatok',
        selectAll: 'Az összes kijelölése',
        fieldNotRequired: 'Választható mező',
        partnerMarketingTextLess: 'Szeretne tőlünk e-mailben, SMS-ben vagy telefonon értesítéseket kapni, a mi vagy a Partnereink legjobb ajánlatairól, akcióiról, versenyeiről, illetve egyéb érdekes eseményekről?',
        partnerMarketingTextMore: 'Megígérjük, hogy nem spamelünk! Ha megunja üzeneteinket, bármikor visszavonhatja beleegyezését.',
        tradeMarketingTextLess: 'Szeretné, hogy megbízható partnereinknek továbbítsuk az Ön e-mail címét vagy telefonszámát annak érdekében, hogy érdekes információkat és ajánlatokat tudjanak küldeni Önnek?',
        tradeMarketingTextMore: 'Biztosítjuk Önt arról, hogy Partnereink csoportjába csak elismert vállalatok tartoznak, amelyek hozzánk hasonlóan nem szeretnek spamelni; a beleegyezését bármikor visszavonhatja.'
      },
      termsLabelDefault: 'Elolvastam és elfogadom <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Töltse le a szolgáltatási szabályzatot tartalmazó fájlt" rel="noreferrer">A fizetési szolgáltatások</a> szabályzata és <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Töltse le a Autopay S.A. adatvédelmi szabályzatát tartalmazó fájlt">Adatvédelmi szabályzatot</a>. Kérem a szolgáltatás azonnali teljesítését, és a szerződéstől való elállás esetén tudomásul veszem, hogy a szerződéstől való elállásig kérésemre teljesített szolgáltatásokkal kapcsolatban viselt költségek visszatérítését nem kapok.',
      termsReccuring: 'Amennyiben szeretne fizetést teljesíteni, fogadja el <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Töltse le a szolgáltatás szabályzatát tartalmazó fájlt" rel="noreferrer">Szabályzat</a> és <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Töltse le a Autopay S.A. adatvédelmi szabályzatát tartalmazó fájlt">Autopay S.A. adatvédelmi szabályzatát</a>, hozzájárulást a fizetési szolgáltatás azonnali teljesítéséhez és hozzájárulást a bankkártyám megterheléséhez.',
      termsReccuringMore1: 'Hozzájárulok a szolgáltatás azonnali teljesítéséhez, és a szerződéstől való elállás esetén tudomásul veszem, hogy a szerződéstől való elállásig kérésemre teljesített szolgáltatásokkal kapcsolatban viselt költségek visszatérítését nem kapok.',
      termsReccuringMore2: 'Hozzájárulok ahhoz, hogy a Autopay S.A. a bankkártyámat rendszeresen megterhelje a tranzakciók teljesítése céljából a(z) {receiverName} javára. A bankkártya rendszeres megterhelésének feltételeit tartalmazó szabályzat <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Töltse le a szolgáltatási szabályzatot tartalmazó fájlt" rel="noreferrer">A fizetési szolgáltatáso</a> szabályzata. Kijelentem, hogy tisztában vagyok a bankkártyám megterhelésének időpontjaival, gyakoriságával és a megterhelés összegének meghatározásának a szabályaival, valamint jelen hozzájárulás visszavonásának módjával, amelyet a(z) {receiverName}-val/vel egyeztettem. A bankkártyám rendszeres megterhelésével kapcsolatos kérdések esetén megkeresem a {receiverName}-t.',
      mwfClauseLess: 'Amennyiben fizetni szeretne, fogadja el a feltételeket <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Általános Szerződési Feltételek" rel="noreferrer">Regulamin</a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="A Autopay S.A. adatvédelmi szabályzata">A Autopay S.A. adatvédelmi irányelvei</a>, hozzájárul ahhoz, hogy a Autopay S.A.-n keresztül tranzakciót indítson a bankjában, engedélyezi a tranzakció részleteinek átadását a címzettnek, és kérelmezi a fizetési szolgáltatás azonnali végrehajtását.',
      mwfClauseMore: 'Azt akarom, hogy a szolgáltatást azonnal hajtsák végre, és tudom, hogy nem kapok visszatérítést a felmerült költségekért a szerződéstől való elállás esetén.',
      mwfClauseARIA: 'Ha fizetni szeretne, fogadja el a szabályzatot és a Autopay S.A. adatvédelmi szabályzatát, és járuljon hozzá a tranzakciók indításához bankjában a Autopay S.A.-n keresztül. valamint a tranzakció részleteinek átadása a kedvezményezettnek és a fizetési szolgáltatás azonnali végrehajtásának kérése. Azonnali szolgáltatást szeretnék, és tudom, hogy a szerződéstől való elállás esetén nem kapok visszatérítést. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Töltse le a fájlt a szolgáltatási feltételekkel</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Töltse le a fájlt a Autopay S.A. adatvédelmi szabályzatából.</a>',
      paymentInfo: 'A fizetési megbízás a Autopay S.A.-n keresztül történik (székhelye: Sopot) és a bankja által meghatározott feltételeknek megfelelően kerül végrehajtásra. A bank kiválasztása után a fizetési tranzakció engedélyezésére lesz megkérve.',
      changePaywayLink1: 'Még mindig meglehet',
      changePaywayLink2: 'változtatni a fizetési módot',
      linkMore: 'TÖBB',
      linkShowMoreTitle: 'További információk megtekintése',
      linkLess: 'KEVESEBB',
      linkShowLessTitle: 'További információk elrejtése',
      submitButton: {
        visaMobile: 'Fizetni',
        fastTransfer: 'Átutalási adatok létrehozása',
        mwf: 'Elfogadom és fizetek',
        pis: 'Elfogadom és elindítom a fizetést',
        auto: 'Rendszeres fizetés rendelése',
        autoPayment: 'Fizetés megrendelése',
        other: 'Fizet',
        orange: 'Megerősít',
        c2pNext: 'Next',
        c2pAccept: 'Elfogadom',
        c2pSaveAndPay: 'Mentés és fizetés'
      }
    },
    frData: {
      pageTitle: 'Részletes adatok az átutaláshoz',
      header: 'Átutalás megrendelése a megadott adatok felhasználásával',
      nrbLabel: 'Címzett fiókja',
      nameLabel: 'Címzett neve',
      addressLabel: 'Címzett címe',
      titleLabel: 'Közlemény',
      amountLabel: 'Átutalás összege',
      timeLabel: 'A tranzakció végrehajtása',
      linkCopy: 'Másolás',
      linkGetPdf: 'PDF letöltése adatokkal',
      linkGetPdfTitle: 'Megnyílik egy új ablakban',
      linkGoToBank: 'Jelentkezzen be a bankjába',
      info: 'Lépjen be a bankfiókjába. Másolja át az adatokat a banki átutaláshoz. Fontos, hogy az adatok változatlanok maradjanak, ne módosítsa az összeget, a valutát, a címet stb.'
    },
    processing: {
      pageTitle: 'Várakozási oldal a tranzakció feldolgozására',
      loaderAnimation: 'Animáció jelenik meg várakozás közben',
      header: 'Készítjük a fizetési oldalt.',
      description: 'Várj még egy kicsit...',
      info: {
        header: 'Emlékezzen!',
        description: 'A tranzakció befejezése után ne zárja le az oldalt - ha ezt megteszi, a pénzét nem küldjük el a címzettnek.'
      },
      loaderText: 'Az adatátvitel folyamatban van.<br>Maximum 30 másodpercet vesz igénybe.'
    }
  },
  thankYou: {
    pageTitle: 'Információ a tranzakció eredményéről',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Visszatérés a partner webhelyére'
    },
    negative: {
      header: 'A fizetés nem történt meg a tranzakcióengedélyezés hiánya miatt.',
      description: 'Ha továbbra is egy adott viszonteladó vagy szolgáltató ajánlatát szeretné igénybe venni – térjen vissza a webhelyére, válasszon ki egy terméket vagy szolgáltatást és fizessen az Ön által kívánt módon.'
    },
    pending: {
      header: 'Köszönjük, hogy a fizetési szolgáltatását vette igénybe.',
      description: 'A tranzakció állapotának ellenőrzése folyamatban van. Amikor a folyamat befejeződik, tájékoztatást kap a fizetés állapotáról az e-mail postafiókjára.'
    },
    positive: {
      header: 'Köszönjük!, A fizetési tranzakció sikeresen befejeződött.',
      description: 'A visszaigazolást elküldjük az Ön által megadott e-mail címre. '
    }
  },
  regulationsApproval: {
    header: 'Fogadja el a tranzakciót'
  },
  wait: {
    pageTitle: 'Várakozó oldal'
  },
  sessionTimeout: {
    pageTitle: 'Oldal az ülés vége után',
    header: 'A tranzakció befejezésének ideje lejárt',
    description: 'Bármikor visszatérhet a partner webhelyére, újra leadhatja a megrendelését vagy igénybe veheti a szolgáltatást, illetve újra fizethet az Ön által  kiválasztott módon. '
  },
  paywayError: {
    pageTitle: 'A kiválasztott fizetési csatorna nem érhető el',
    header: 'Sajnos a kiválasztott fizetési mód jelenleg nem elérhető.',
    description: 'Kérjük, térjen vissza a partneroldalra, és próbálja újra később.'
  },
  loader: {
    defaultAnimation: 'Az oldal betöltése közben animáció jelenik meg',
    slider: 'Csúszka változó szövegekkel',
    text1: 'A Autopay-nál a karbonsemlegességre törekszünk. Ön is ezt teszi, amikor minket választ.',
    text2: 'A Autopay-nál a munkahelyünkre kerékpározunk, ezzel csökkentjük az üvegházhatást okozó gázok légkörbe történő kibocsátását. Ön is ezt teszi, amikor minket választ. ',
    text3: 'A Autopay-nál a zöld energia hajt minket. Önt is, amikor igénybe veszi szolgáltatásainkat.',
    text4: 'A Autopay-nál csökkentjük a papírfogyasztást. Ön is ezt teszi, amikor a szolgáltatásainkat válassza.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Részletek',
    headerAriaLabel: 'Tranzakció részletei',
    amountLabel: 'Követelés összege',
    receiverLabel: 'Címzett',
    orderLabel: 'Rendelés száma',
    amountOryginalCurrencyLabel: 'Az összeg {currency}',
    currencyRateLabel: 'Árfolyam',
    feeLabel: 'Pénztári díj',
    customerNumberLabel: 'Vevő száma',
    invoiceNumberLabel: 'Számlaszám',
    accountNumberLabel: 'Bankszámla szám',
    paywayLabel: 'Fizetési mód',
    paywayName: 'Online banki átutalás',
    linkBack: 'Vissza a boltba',
    linkBackTitle: 'Hagyja ki a tranzakciót, és térjen vissza az üzletbe',
    linkMore: 'több',
    linkLess: 'kevesebb',
    toggleBtnUnfoldAriaLabel: 'Megrendelés részleteinek megnyitása',
    toggleBtnFoldAriaLabel: 'Rendelés részleteinek bezárása'
  },
  error: {
    pageTitle: 'Hibaoldal',
    linkBackUrlSet: 'Visszatérés a partner webhelyére',
    linkBackUrlNotSet: 'Ugrás a súgóoldalra',
    sessionTimeout: {
      title: 'A tranzakció befejezésének ideje lejárt',
      message: 'Bármikor visszatérhet a partner webhelyére, újra leadhatja a megrendelését vagy igénybe veheti a szolgáltatást, illetve újra fizethet az Ön által  kiválasztott módon. '
    },
    notFoundError: {
      pageTitle: '404-es hibaoldal – az oldal nem található',
      title: 'Úgy tűnik, hogy túl messzire került, és nem tudta megtalálni a kívánt webhelyet.',
      message: 'Lehetséges okok: az oldal nem létezik vagy törölték, a szervizmunka folyamatban van, az oldal címe helytelen, technikai hiba történt.'
    },
    generalError: {
      title: 'Sajnáljuk',
      message: 'Jelenleg nem tudjuk végrehajtani az átutalását.'
    },
    errFieldNotFound: {
      title: 'Sajnáljuk',
      message: ''
    },
    errBadClientSource: {
      title: 'Sajnáljuk',
      message: ''
    },
    nrParametersError: {
      title: 'Sajnáljuk',
      message: 'Jelenleg nem tudjuk végrehajtani az átutalását.'
    },
    transactionOutdated: {
      title: 'A fizetés teljesítésének ideje lejárt.',
      message: 'Próbálja újra.'
    },
    linkValidityTimeOutdated: {
      title: 'A fizetés teljesítésének ideje lejárt.',
      message: 'Próbálja újra'
    },
    transactionValidityTimeOutdated: {
      title: 'A fizetés teljesítésének ideje lejárt.',
      message: 'Próbálja újra.'
    },
    multiplyTransaction: {
      title: 'Sajnáljuk',
      message: 'A tranzakció már létezik és kifizetésre vár.'
    },
    transactionCanceled: {
      title: 'Sajnáljuk',
      message1: 'A tranzakciót törölték.',
      message2: 'Próbálja újra'
    },
    multiplyPaidTransaction: {
      title: 'Sajnáljuk',
      message: 'A tranzakció már ki van fizetve.'
    },
    transactionReject: {
      title: 'Sajnáljuk',
      message1: 'Hoppá, valami nem sikerült.',
      message2: 'Kapu javítása folyamatban van…'
    },
    bankDisabled: {
      title: 'Sajnáljuk',
      message: 'A kiválasztott bank jelenleg nem érhető el.'
    },
    bankTemporaryMaintenance: {
      title: 'Sajnáljuk',
      message: 'Jelenleg nem tudjuk végrehajtani az átutalását.'
    },
    insufficientStartAmount: {
      title: 'Sajnáljuk',
      message: 'Túllépte az átutalási összeg határértékét.'
    },
    startAmountOutOfRange: {
      title: 'Sajnáljuk',
      message: 'Túllépte az átutalási összeg határértékét.'
    },
    nonAccountedLimitExceeded: {
      title: 'Sajnáljuk',
      message: 'A fizetés nem lehetséges, az eladó elérte a havi értékesítési határértéket.'
    }
  },
  confirmation: {
    pageTitle: 'A fizetés folyamatával kapcsolatos információk',
    linkBack: 'Visszamegyek az oldalra',
    linkBackTimeout: 'Vissza a partner webhelyére itt: ',
    incorrectAmount: {
      header: 'Rossz összeg',
      description: 'Nem utaltuk át a kifizetést a címzettnek, mert az összeg hibás volt.',
      description2: 'Kattintson a gombra, hogy visszatérjen az oldalra, és ismételje meg. Ne feledje, hogy semmilyen adatot nem szabad megváltoztatni.'
    },
    multiplePaid: {
      header: 'Kettős befizetés',
      description: 'A címzett kétszer kapta meg a kifizetést. Ne aggódjon – visszakapja a pénzét.',
      description2: 'Vegye fel a kapcsolatot a címzettel, és küldje el neki a fizetési visszaigazolást.'
    },
    negative: {
      header: 'Az idő vége',
      description: 'Nem utaltuk át a kifizetést a címzettnek, mert a visszaigazolási idő lejárt.',
      description2: 'Kattintson a gombra az oldalra való visszatéréshez, és ismételje meg.'
    },
    notFound: {
      header: 'Nincs fizetés',
      description: 'Hiba történt a fizetés feldolgozása közben. De semmi sincs veszve.',
      description2: 'Kattintson a gombra az oldalra való visszatéréshez, és ismételje meg.'
    },
    outdated: {
      header: 'Az idő vége',
      description: 'Nem utaltuk át a kifizetését a címzettnek.',
      description2: 'Kattintson a gombra az oldalra való visszatéréshez, és ismételje meg.'
    },
    outdatedPaidNotRefunded: {
      header: 'Az idő vége',
      description: 'Nem utaltuk át a kifizetését a címzettnek. Ha pénzt vontak le a számlájáról, ne aggódjon, néhány napon belül visszakapja a visszatérítést.',
      description2: 'Kattintson a gombra az oldalra való visszatéréshez, és ismételje meg.'
    },
    outdatedPaidRefunded: {
      header: 'Az idő vége',
      description: 'Nem utaltuk át a kifizetését a címzettnek. Ha a pénzt levonták a számlájáról, ne aggódjon, már visszatérítettük.',
      description2: 'Kattintson a gombra az oldalra való visszatéréshez, és ismételje meg.'
    },
    pending: {
      header: 'Folyamatban',
      description: 'Feldolgozzuk a fizetését. Értesíteni fogjuk, ha megérkezik a címzetthez.'
    },
    positive: {
      header: 'Fizetett',
      visaVideo: 'Sikeres tranzakció után lejátszott reklámvideó',
      description: 'A pénzét átutaltuk a címzettnek.'
    }
  },
  messages: {
    accessForbidden: 'Nincs hozzáférés',
    internalServerError: 'Váratlan hiba történt. Próbálkozzon újra később.',
    validators: {
      required: 'Kötelező mező',
      pattern: 'Adjon meg egy érvényes értéket',
      emailRequired: 'E-mail cím szükséges.',
      emailNotValid: 'Az e-mail cím hibát tartalmaz. Adjon meg érvényes e-mail címet',
      emailNotRecognized: 'A megadott e-mail cím nincs regisztrálva',
      emailIdentityLookup: 'Nem sikerült az e-mail cím ellenőrzése',
      emailLocked: 'A megadott e-mail cím ideiglenesen le van tiltva a Mastercard rendszerben',
      paywayRequired: 'Fizetési mód kiválasztása',
      creditCardRequired: 'Töltse ki az összes mezőt',
      phoneRequired: 'Adjon meg egy érvényes telefonszámot',
      regulationRequired: 'Általános szerződési feltételek elfogadása',
      requiredConsent: 'Hozzájárulás szükséges',
      codeNotValid: 'A megadott kód nem érvényes',
      codeAccountLocked: 'A számla ideiglenesen zárolva van a Mastercard rendszerben'
    },
    validatorsAria: {
      required: 'Figyelem. Az űrlapmező nincs kitöltve. Kötelező mező.',
      pattern: 'Figyelem. A mező érvénytelen értéket tartalmaz.',
      emailRequired: 'Figyelem. Az e-mail mező nincs kitöltve. Kérjük valós e-mail címet adjon meg.',
      emailNotValid: 'Figyelem. Az Ön e-mail címe hibát tartalmaz. Kérjük valós e-mail címet adjon meg.',
      emailNotRecognized: 'Figyelem. A megadott e-mail cím nincs regisztrálva.',
      emailIdentityLookup: 'Figyelem. A megadott e-mail címet nem lehetett ellenőrizni',
      emailLocked: 'Figyelem. A megadott e-mail cím ideiglenesen le van tiltva a Mastercard rendszerben.',
      paywayRequired: 'Figyelem. A fizetés nincs kiválasztva. Válassz fizetési módot.',
      creditCardRequired: 'Figyelem. Töltse ki az összes mezőt a hitelkártya adataival.',
      phoneRequired: 'Figyelem. A telefonszám mező hibát tartalmaz. Adjon meg egy érvényes telefonszámot.',
      codeNotValid: 'Figyelem! A megadott kód érvénytelen.',
      codeAccountLocked: 'Figyelem. A számla ideiglenesen le van tiltva a Mastercard rendszerben.'
    },
    success: {
      copy: 'Átmásolva!',
      codeSent: 'A kód elküldve!'
    },
    errors: {
      getPdf: 'Nem sikerült letölteni a PDF fájlt!',
      copy: 'Nem sikerült átmásolni!',
      thankYouUpdate: 'Nem sikerült elmenteni az e-mail címét!',
      groupInactive: 'Jelenleg nem lehet átvitelt végrehajtani a {groupName} csoport használatával. Kérjük, válasszon másik fizetési módot.',
      paywayInactive: 'Jelenleg technikai okok miatt lehetetlen átutalást végrehajtani a kiválasztott bankból. Válasszon másik bankot vagy fizetési módot.',
      googlePayInactive: 'Sajnos a Google Pay nem érhető el a készülékén.',
      applePayInactive: 'Az Apple Pay elérhető a Safari böngésző és az iOS operációs rendszerrel felszerelt eszközök felhasználói számára.',
      codeNotSent: 'Nem sikerült a kód újraküldése!',
      codeCounterExceeded: 'Túllépte a megengedett próbálkozások számát. Adja meg kártyaadatait kézzel, vagy használjon másik fizetési módot.'
    }
  },
  modal: {
    closeButton: 'Bezár',
    confirmButton: 'Értem'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Ismétlődő fizetés',
        description: 'Adja meg a fizetéshez használni kívánt kártya adatait. Az automatikus fizetéseket később levonják a kártyájáról.',
        labelInitWithRefund: 'Ellenörző fizetés',
        labelInitWithRefundOrange: 'Fizetési kártya hozzáadása',
        labelInitWithPayment: 'Fizetés kártyával',
        descriptionInitWithRefund: 'Adjon meg kártya adatokat, amelyet ellenőrizni szeretne a jövő fizetésekhez. A további lépésben leveszünk egy forintot a megadott kártyáról, hogy leellenőrizzük az adatok megfelelősségét. Az ellenőrzés után visszaadjuk az összeget.',
        descriptionInitWithPayment: 'Adja meg annak a kártyának az adatait, amellyel elvégzi a tranzakciót és egyben leellenőrzi a jövő fizetésekhez.'
      },
      blik: {
        label: 'BLIK',
        description: 'Fizessen azonnal a BLIK kód megadásával, bejelentkezés és regisztráció nélkül'
      },
      card: {
        label: 'Bankkártyás fizetés',
        description: 'Fizesse ki bankkártyájával'
      },
      pbl: {
        label: 'Online utalás',
        description: 'Válassza ki azt a bankot, ahonnan fizetni szeretne',
        descriptionVerify: 'Online személyazonosság-ellenőrzés',
        descriptionServiceVerify: 'Válassza ki azt a bankot, amely lehetővé teszi az on-line szolgáltatás ellenőrzését'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Visa Mobil fizetés'
      },
      wallet: {
        label: 'Virtuális pénztárca',
        description: 'Fizetés anélkül, hogy bejelentkezne az online bankfelületre'
      },
      installments: {
        label: 'Törlesztés',
        description: 'Fizesse vásárlásait kényelmes részletfizetéssel'
      },
      alior: {
        descriptionInfo1: 'Fizetjen vásárlásaiért kényelmes részletfizetéssel',
        descriptionInfo2: '(ellenőrizze a részleteket)',
        aliorModal: {
          text1: 'A vásárlást végző weboldal beállításaitól függően a következő változatokban vásárolhat részletfizetéssel termékeket:',
          text2: '<ul><li>10 részlet 0%</li><li>20 részlet 0%</li><li>3-tól 48 részletig 1%-os havi költséggel (a megadott reprezentatív példa szerint adott tranzakcióhoz)</li></ul>'
        }
      },
      otp: {
        label: 'Fizetés később',
        description: 'Vásároljon most, fizessen később',
        descriptionInfo1Mwf: 'Fizessen később a számláért – legfeljebb 45 napig, vagy több egyenlő részletben',
        descriptionInfo1Ecommerce: 'Fizessen később – legfeljebb 45 napig, vagy több egyenlő részletben',
        descriptionInfo2Mwf: 'Információ a hitelközvetítőről és a költségekről',
        descriptionInfo2Ecommerce: 'Költséginformáció',
        labelPayka: 'Payka',
        descriptionPayka: 'Fizessen később a számláért – egy összegben akár 30 napos halasztással vagy 4 egyenlő részletben <a href="" id="paykaShowInModal">Információ a hitelközvetítőről és a költségekről</a>',
        descriptionBlikPayLater: 'A szolgáltatás a Millenium Banknál és a VeloBanknál érhető el',
        paykaModalMwf: {
          header1: 'A hitelközvetítőre vonatkozó információk',
          text1: 'A Sopot város székhelyű Autopay S.A., a  Gdańsk város székhelyű Paytree S.A. hitelközvetítőként jár el (a továbbiakban: a Hitelező), amely jogosult a hitelajánlat bemutatására az ügyfeleknek és a Hitelező weboldalára történő átirányításra, beleértve a hitelkérelmi űrlaphoz történő átirányítást is. Az ajánlat részletei: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Költségadatok',
          text2: 'Fizessen később – legfeljebb 45 napig, vagy több egyenlő részletben. Költségadatok egy reprezentatív példához: Törlesztés 45 napon belül THM 0%; Részletfizetés THM 86,83%. Az ajánlat részletei: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Költségadatok',
          text2: 'Fizessen később – legfeljebb 45 napig, vagy több egyenlő részletben. Költségadatok egy reprezentatív példához: Törlesztés 45 napon belül THM 0%; Részletfizetés THM 86,83%. Az ajánlat részletei: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Vásárolj most, fizess 30 napon belül',
          header2: 'BLIK kód',
          text2: 'Beírja a BLIK kódot, és 4000 PLN-ig kínálunk vásárlási limitet',
          header3: 'Termékeket vásárol, és később fizet értük',
          text3: 'Van ideje ellenőrizni, hogy minden megfelel-e Önnek',
          header4: '30 napon belül ingyenesen fizet',
          text4: 'Vásárlásait az üzlet által meghatározott határidőn belül is visszaküldheti'
        }
      },
      companyPayments: {
        label: 'Vállalati fizetés',
        description: 'Halaszd el 30 nappal a vállalati vásárlások kifizetési dátumát'
      },
      transfer: {
        label: 'Átutalás részletei',
        description: 'Átutalás igénylése a megadott adatok felhasználásával'
      },
      other: {
        label: 'Egyéb',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Sajnáljuk! Jelenleg nem tudjuk feldolgozni az átutalást. A kiválasztott bank jelenleg nem érhető el.',
        blockMultipleTransactions: 'Sajnáljuk! A tranzakció már létezik, és kifizetésre vár.',
        blockPaidTransactions: 'Sajnáljuk! A tranzakció ki lett már fizetve. A fizetést nem lehet megismételni. ',
        canceledTransactions: 'Ezt a tranzakciót törölték, már nem érhető el. Indítson új tranzakciót.',
        declined: 'Az engedélyezés megtagadása.',
        generalError: 'Átmeneti csatlakozási hiba. Jelenleg nem tudjuk feldolgozni az átutalását. Szeretettel várjuk később.',
        insufficientStartAmount: 'Sajnáljuk! Túllépte az átutalási összeg határértékét.',
        internalServerError: 'Hoppá... Sikertelen :(. Próbáljuk megoldani ezt a problémát. Próbálkozzon újra később.',
        nonAccountedLimitExceeded: 'A fizetés nem lehetséges, az eladó elérte a havi értékesítési korlátot.',
        outdatedError: 'A fizetés teljesítésének ideje lejárt..',
        paid: 'A tranzakció sikeresen befejeződött.',
        paywayLoaded: 'Gyorsutalási oldal betöltése',
        paywaylistLoaded: 'A fizetési mód kiválasztási oldala betöltődött'
      }
    }
  },
  maintenance: {
    text1: 'A Autopay fizetési kapuja az újévi takarítás alatt van, így jelenleg nem lehet fizetést kezdeményezni. Elnézést kérünk a kellemetlenségért.',
    text2: 'A kapu január 16. 10:00 órától ismét működni fog.',
    text3: 'Megértését köszönjük.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Adja meg a vezeték- és vezetéknevet',
      flowLabel: 'Név és vezetéknév',
      placeholder: 'Adja meg a vezeték- és vezetéknevet'
    },
    nip: {
      label: 'Adja meg adóazonosító számát',
      flowLabel: 'NIP',
      placeholder: 'Adja meg adóazonosító számát'
    }
  }
}
